import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import BlogListing from '../components/BlogListing';

const ContactThanksPage = () => {
  const {
    datoCmsContact: { bannerImage },
    site: {
      globalSeo: { titleSuffix },
    },
  } = useStaticQuery(graphql`
    query ContactThanksPageQuery {
      datoCmsContact {
        bannerImage {
          ...SubPageBannerImageFragment
        }
      }
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `);

  const title = 'Thanks for getting in touch';

  return (
    <Layout>
      <HelmetDatoCms title={title} titleTemplate={`%s${titleSuffix}`} />
      <main>
        <Banner
          heading={title}
          image={bannerImage}
          text="We will get back to you shortly, but in the meantime see what's been going on at Barnstondale by checking out our latest blog posts below."
          isThanksPage={true}
        />
        <BlogListing heading="What's going on at Barnstondale?" />
      </main>
    </Layout>
  );
};

export default ContactThanksPage;
